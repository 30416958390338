import { Grid } from '@material-ui/core';
import { PageTitles } from '../../shared/constants';
import PageTitleDescription from '../Common/PageTitleDescription';
import TeamMembers from './TeamMembers';
import * as React from "react"

interface TeamProps {
  data: Array<any>,
  classes ?: any;
}

export const Team = ({ classes, data }: TeamProps) => (
  <Grid container justify="center">
    <Grid item xs={12}>
      <PageTitleDescription title={PageTitles.team.title} description={PageTitles.team.description} classes={classes} />
    </Grid>
    <Grid item xs={12}>
      <TeamMembers members={data} />
    </Grid>
  </Grid>
);
